import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useRef, useEffect, useState } from 'react';
import { Playground } from 'docz';
import '../../dist/css/bolt-css.css';
import { Input } from '../../dist/js/bolt.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "input"
    }}>{`Input`}</h1>
    <p>{`O componente Imput é um componente composto que permite trabalhar com inputs de texto e numéricos, além de permitir adicionar ícones e oferecer formas de manipular
seu comportamento.`}</p>
    <h2 {...{
      "id": "texto-simples"
    }}>{`Texto simples`}</h2>
    <br />
    <Playground __position={1} __code={'() => {\n  const [input, setInput] = useState()\n  const inputRef = useRef()\n  useEffect(() => {\n    if (inputRef.current && !input) {\n      setInput(new Input(inputRef.current))\n    }\n    return () => {\n      if (input) input.destroy()\n    }\n  }, [inputRef.current, input])\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap\">\n      <div ref={inputRef} data-label=\"nome\"></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useEffect,
      useState,
      Playground,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [input, setInput] = useState();
        const inputRef = useRef();
        useEffect(() => {
          if (inputRef.current && !input) {
            setInput(new Input(inputRef.current));
          }

          return () => {
            if (input) input.destroy();
          };
        }, [inputRef.current, input]);
        return <div className="tw-flex tw-gap-4 tw-flex-wrap">
        <div ref={inputRef} data-label="nome"></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "tooltip"
    }}>{`Tooltip`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Clicando no ícone, a tooltip pode permanecer visível.`}</p>
    </blockquote>
    <br />
    <Playground __position={4} __code={'() => {\n  const [input, setInput] = useState()\n  const inputRef = useRef()\n  useEffect(() => {\n    if (inputRef.current && !input) {\n      setInput(new Input(inputRef.current))\n    }\n    return () => {\n      if (input) input.destroy()\n    }\n  }, [inputRef.current, input])\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap\">\n      <div\n        ref={inputRef}\n        data-label=\"MDR\"\n        data-tooltip=\"Sigla em inglês para Merchant Discount Rate. Conhecida como Taxa de Desconto do Lojista...\"\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useEffect,
      useState,
      Playground,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [input, setInput] = useState();
        const inputRef = useRef();
        useEffect(() => {
          if (inputRef.current && !input) {
            setInput(new Input(inputRef.current));
          }

          return () => {
            if (input) input.destroy();
          };
        }, [inputRef.current, input]);
        return <div className="tw-flex tw-gap-4 tw-flex-wrap">
        <div ref={inputRef} data-label="MDR" data-tooltip="Sigla em inglês para Merchant Discount Rate. Conhecida como Taxa de Desconto do Lojista..."></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <br />
    <Playground __position={7} __code={'() => {\n  const [input, setInput] = useState()\n  const inputRef = useRef()\n  useEffect(() => {\n    if (inputRef.current && !input) {\n      setInput(new Input(inputRef.current))\n    }\n    return () => {\n      if (input) input.destroy()\n    }\n  }, [inputRef.current, input])\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap\">\n      <div\n        ref={inputRef}\n        data-label=\"nome\"\n        data-disabled={true}\n        data-value=\"Disabled\"\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useEffect,
      useState,
      Playground,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [input, setInput] = useState();
        const inputRef = useRef();
        useEffect(() => {
          if (inputRef.current && !input) {
            setInput(new Input(inputRef.current));
          }

          return () => {
            if (input) input.destroy();
          };
        }, [inputRef.current, input]);
        return <div className="tw-flex tw-gap-4 tw-flex-wrap">
        <div ref={inputRef} data-label="nome" data-disabled={true} data-value="Disabled"></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "manipulando-o-valor"
    }}>{`Manipulando o valor`}</h2>
    <br />
    <p>{`Em alguns casos pode ser necessário manipular o valor de entrada. O componente Input fornece o método onChange para ser implementado. Esse método é chamado a cada mudança
no valor do input.`}</p>
    <br />
    <Playground __position={11} __code={'() => {\n  const [input, setInput] = useState()\n  const [value, setValue] = useState(\'digite um texto\')\n  const inputRef = useRef()\n  useEffect(() => {\n    if (inputRef.current && !input) {\n      setInput(new Input(inputRef.current))\n    }\n    if (input) {\n      input.onChange = handleChange\n    }\n    return () => {\n      if (input) input.destroy()\n    }\n  }, [inputRef.current, input])\n  const handleChange = e => {\n    setValue(e.target.value)\n  }\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap tw-items-center\">\n      <div ref={inputRef} data-label=\"nome\"></div>\n      <p className=\"tw-body2 tw-ml-2 tw-text-alert-dark\">{value}</p>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useEffect,
      useState,
      Playground,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [input, setInput] = useState();
        const [value, setValue] = useState('digite um texto');
        const inputRef = useRef();
        useEffect(() => {
          if (inputRef.current && !input) {
            setInput(new Input(inputRef.current));
          }

          if (input) {
            input.onChange = handleChange;
          }

          return () => {
            if (input) input.destroy();
          };
        }, [inputRef.current, input]);

        const handleChange = e => {
          setValue(e.target.value);
        };

        return <div className="tw-flex tw-gap-4 tw-flex-wrap tw-items-center">
        <div ref={inputRef} data-label="nome"></div>
        <p className="tw-body2 tw-ml-2 tw-text-alert-dark">{value}</p>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "helper-text"
    }}>{`Helper Text`}</h2>
    <br />
    <p>{`Em alguns casos pode ser necessário a adição de um texto de auxílio, ou mesmo a informação de uma validação incorreta. Pode-se usar a propriedade helpertext para esses casos.`}</p>
    <br />
    <Playground __position={15} __code={'() => {\n  const [input, setInput] = useState()\n  const inputRef = useRef()\n  useEffect(() => {\n    if (inputRef.current && !input) {\n      setInput(new Input(inputRef.current))\n    }\n    return () => {\n      if (input) input.destroy()\n    }\n  }, [inputRef.current, input])\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap\">\n      <div\n        ref={inputRef}\n        data-label=\"nome\"\n        data-helpertext=\"nome completo\"\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useEffect,
      useState,
      Playground,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [input, setInput] = useState();
        const inputRef = useRef();
        useEffect(() => {
          if (inputRef.current && !input) {
            setInput(new Input(inputRef.current));
          }

          return () => {
            if (input) input.destroy();
          };
        }, [inputRef.current, input]);
        return <div className="tw-flex tw-gap-4 tw-flex-wrap">
        <div ref={inputRef} data-label="nome" data-helpertext="nome completo"></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "manipulação-de-validação"
    }}>{`Manipulação de validação`}</h2>
    <br />
    <p>{`Para informar o usuário de uma falha na validação do campo, pode-se usar a propriedade error, em conjunto com a propriedade helpertext.`}</p>
    <br />
    <Playground __position={19} __code={'() => {\n  const [input, setInput] = useState()\n  const [value, setValue] = useState(\'\')\n  const [error, setError] = useState(null)\n  const inputRef = useRef()\n  useEffect(() => {\n    if (inputRef.current && !input) {\n      setInput(new Input(inputRef.current))\n    }\n    if (input) {\n      input.onChange = handleChange\n    }\n    return () => {\n      if (input) input.destroy()\n    }\n  }, [inputRef.current, input])\n  const handleChange = e => {\n    setError(null)\n    setValue(e.target.value)\n  }\n  const clickHandle = () => {\n    setError(\'Por favor, digite seu nome.\')\n  }\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap tw-items-center\">\n      <div\n        ref={inputRef}\n        data-label=\"nome\"\n        data-error={Boolean(error)}\n        data-helpertext={Boolean(error) ? error : \'\'}\n      ></div>\n      <button className=\"tw-btn tw-btn-primary\" onClick={clickHandle}>\n        add error\n      </button>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useEffect,
      useState,
      Playground,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [input, setInput] = useState();
        const [value, setValue] = useState('');
        const [error, setError] = useState(null);
        const inputRef = useRef();
        useEffect(() => {
          if (inputRef.current && !input) {
            setInput(new Input(inputRef.current));
          }

          if (input) {
            input.onChange = handleChange;
          }

          return () => {
            if (input) input.destroy();
          };
        }, [inputRef.current, input]);

        const handleChange = e => {
          setError(null);
          setValue(e.target.value);
        };

        const clickHandle = () => {
          setError('Por favor, digite seu nome.');
        };

        return <div className="tw-flex tw-gap-4 tw-flex-wrap tw-items-center">
        <div ref={inputRef} data-label="nome" data-error={Boolean(error)} data-helpertext={Boolean(error) ? error : ''}></div>
        <button className="tw-btn tw-btn-primary" onClick={clickHandle}>
          add error
        </button>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "clear-text"
    }}>{`Clear Text`}</h2>
    <br />
    <p>{`O Input disponibiliza um botão para limpar rapidamente o valor do campo. Pode ser habilitado pela propriedade clearicon.`}</p>
    <br />
    <Playground __position={23} __code={'() => {\n  const [input, setInput] = useState()\n  const inputRef = useRef()\n  useEffect(() => {\n    if (inputRef.current && !input) {\n      setInput(new Input(inputRef.current))\n    }\n    return () => {\n      if (input) input.destroy()\n    }\n  }, [inputRef.current, input])\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap\">\n      <div ref={inputRef} data-label=\"nome\" data-clearicon={true}></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useEffect,
      useState,
      Playground,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [input, setInput] = useState();
        const inputRef = useRef();
        useEffect(() => {
          if (inputRef.current && !input) {
            setInput(new Input(inputRef.current));
          }

          return () => {
            if (input) input.destroy();
          };
        }, [inputRef.current, input]);
        return <div className="tw-flex tw-gap-4 tw-flex-wrap">
        <div ref={inputRef} data-label="nome" data-clearicon={true}></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "start-icon"
    }}>{`Start Icon`}</h2>
    <br />
    <p>{`É possível adicionar um ícone no inicio do input utilizando a propriedade starticon.`}</p>
    <br />
    <Playground __position={27} __code={'() => {\n  const [input, setInput] = useState()\n  const inputRef = useRef()\n  useEffect(() => {\n    if (inputRef.current && !input) {\n      setInput(new Input(inputRef.current))\n    }\n    return () => {\n      if (input) input.destroy()\n    }\n  }, [inputRef.current, input])\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap\">\n      <div\n        ref={inputRef}\n        data-label=\"e-mail\"\n        data-clearicon={true}\n        data-starticon=\"mail\"\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useEffect,
      useState,
      Playground,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [input, setInput] = useState();
        const inputRef = useRef();
        useEffect(() => {
          if (inputRef.current && !input) {
            setInput(new Input(inputRef.current));
          }

          return () => {
            if (input) input.destroy();
          };
        }, [inputRef.current, input]);
        return <div className="tw-flex tw-gap-4 tw-flex-wrap">
        <div ref={inputRef} data-label="e-mail" data-clearicon={true} data-starticon="mail"></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "end-icon"
    }}>{`End Icon`}</h2>
    <br />
    <p>{`É possível adicionar um ícone no final do input utilizando a propriedade endicon.`}</p>
    <br />
    <Playground __position={31} __code={'() => {\n  const [input, setInput] = useState()\n  const inputRef = useRef()\n  useEffect(() => {\n    if (inputRef.current && !input) {\n      setInput(new Input(inputRef.current))\n    }\n    return () => {\n      if (input) input.destroy()\n    }\n  }, [inputRef.current, input])\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap\">\n      <div\n        ref={inputRef}\n        data-label=\"Configuração\"\n        data-endicon=\"settings\"\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useEffect,
      useState,
      Playground,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [input, setInput] = useState();
        const inputRef = useRef();
        useEffect(() => {
          if (inputRef.current && !input) {
            setInput(new Input(inputRef.current));
          }

          return () => {
            if (input) input.destroy();
          };
        }, [inputRef.current, input]);
        return <div className="tw-flex tw-gap-4 tw-flex-wrap">
        <div ref={inputRef} data-label="Configuração" data-endicon="settings"></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "manipulando-eventos-dos-ícones"
    }}>{`Manipulando eventos dos ícones`}</h2>
    <br />
    <p>{`Em alguns casos é necessário manipular os eventos dos ícones, para isso o Input disponibiliza os métodos onStartIconclick e onEndIconClick.`}</p>
    <br />
    <Playground __position={35} __code={'() => {\n  const [input, setInput] = useState()\n  const [showPass, setShowPass] = useState(false)\n  const inputRef = useRef()\n  useEffect(() => {\n    if (inputRef.current && !input) {\n      setInput(new Input(inputRef.current))\n    }\n    if (input) {\n      input.onEndIconClick = showHandle\n    }\n    return () => {\n      if (input) input.destroy()\n    }\n  }, [inputRef.current, input])\n  const showHandle = () => {\n    setShowPass(old => !old)\n  }\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap\">\n      <div\n        ref={inputRef}\n        data-label=\"password\"\n        data-endicon={!showPass ? \'eye\' : \'eye-off\'}\n        data-type={!showPass ? \'password\' : \'text\'}\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useEffect,
      useState,
      Playground,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [input, setInput] = useState();
        const [showPass, setShowPass] = useState(false);
        const inputRef = useRef();
        useEffect(() => {
          if (inputRef.current && !input) {
            setInput(new Input(inputRef.current));
          }

          if (input) {
            input.onEndIconClick = showHandle;
          }

          return () => {
            if (input) input.destroy();
          };
        }, [inputRef.current, input]);

        const showHandle = () => {
          setShowPass(old => !old);
        };

        return <div className="tw-flex tw-gap-4 tw-flex-wrap">
        <div ref={inputRef} data-label="password" data-endicon={!showPass ? 'eye' : 'eye-off'} data-type={!showPass ? 'password' : 'text'}></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "type-text"
    }}>{`Type text`}</h2>
    <br />
    <p>{`O Input permite alguns tipos diferentes de dados. Por padrão inicia com o tipo text. O tipo text ainda permite configurar a propriedade maxlength`}</p>
    <br />
    <Playground __position={39} __code={'() => {\n  const [input, setInput] = useState()\n  const [count, setCount] = useState(0)\n  const inputRef = useRef()\n  useEffect(() => {\n    if (inputRef.current && !input) {\n      setInput(new Input(inputRef.current))\n    }\n    if (input) {\n      input.onChange = handleChange\n    }\n    return () => {\n      if (input) input.destroy()\n    }\n  }, [inputRef.current, input])\n  const handleChange = e => {\n    setCount(e.target.value.length)\n  }\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap\">\n      <div\n        ref={inputRef}\n        data-label=\"message\"\n        data-maxlength={10}\n        data-helpertext={`${count} / 10`}\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useEffect,
      useState,
      Playground,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [input, setInput] = useState();
        const [count, setCount] = useState(0);
        const inputRef = useRef();
        useEffect(() => {
          if (inputRef.current && !input) {
            setInput(new Input(inputRef.current));
          }

          if (input) {
            input.onChange = handleChange;
          }

          return () => {
            if (input) input.destroy();
          };
        }, [inputRef.current, input]);

        const handleChange = e => {
          setCount(e.target.value.length);
        };

        return <div className="tw-flex tw-gap-4 tw-flex-wrap">
        <div ref={inputRef} data-label="message" data-maxlength={10} data-helpertext={`${count} / 10`}></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "type-password"
    }}>{`Type password`}</h2>
    <br />
    <p>{`O tipo password permite que o campo de texto fique escondido.`}</p>
    <br />
    <Playground __position={43} __code={'() => {\n  const [input, setInput] = useState()\n  const inputRef = useRef()\n  useEffect(() => {\n    if (inputRef.current && !input) {\n      setInput(new Input(inputRef.current))\n    }\n    return () => {\n      if (input) input.destroy()\n    }\n  }, [inputRef.current, input])\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap\">\n      <div\n        ref={inputRef}\n        data-label=\"password\"\n        data-type=\"password\"\n        data-value=\"123456\"\n        data-endicon=\"lock\"\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useEffect,
      useState,
      Playground,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [input, setInput] = useState();
        const inputRef = useRef();
        useEffect(() => {
          if (inputRef.current && !input) {
            setInput(new Input(inputRef.current));
          }

          return () => {
            if (input) input.destroy();
          };
        }, [inputRef.current, input]);
        return <div className="tw-flex tw-gap-4 tw-flex-wrap">
        <div ref={inputRef} data-label="password" data-type="password" data-value="123456" data-endicon="lock"></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "type-number"
    }}>{`Type number`}</h2>
    <br />
    <p>{`O tipo number permite somente dados numéricos e permite configurar as propriedades max, min e step`}</p>
    <br />
    <Playground __position={47} __code={'() => {\n  const [input, setInput] = useState()\n  const [degrees, setDegrees] = useState(60)\n  const inputRef = useRef()\n  useEffect(() => {\n    if (inputRef.current && !input) {\n      setInput(new Input(inputRef.current))\n    }\n    if (input) {\n      input.onChange = handleChange\n    }\n    return () => {\n      if (input) input.destroy()\n    }\n  }, [inputRef.current, input])\n  const handleChange = e => {\n    setDegrees(e.target.value)\n  }\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap tw-items-center\">\n      <div\n        ref={inputRef}\n        data-label=\"graus\"\n        data-type=\"number\"\n        data-starticon=\"clock\"\n        data-min={60}\n        data-max={180}\n        data-step={15}\n        data-value={60}\n      ></div>\n      <div\n        className=\"tw-w-48 tw-h-48 tw-bg-alert tw-ml-8\"\n        style={{ transform: `rotate(${degrees}deg)` }}\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useEffect,
      useState,
      Playground,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [input, setInput] = useState();
        const [degrees, setDegrees] = useState(60);
        const inputRef = useRef();
        useEffect(() => {
          if (inputRef.current && !input) {
            setInput(new Input(inputRef.current));
          }

          if (input) {
            input.onChange = handleChange;
          }

          return () => {
            if (input) input.destroy();
          };
        }, [inputRef.current, input]);

        const handleChange = e => {
          setDegrees(e.target.value);
        };

        return <div className="tw-flex tw-gap-4 tw-flex-wrap tw-items-center">
        <div ref={inputRef} data-label="graus" data-type="number" data-starticon="clock" data-min={60} data-max={180} data-step={15} data-value={60}></div>
        <div className="tw-w-48 tw-h-48 tw-bg-alert tw-ml-8" style={{
            transform: `rotate(${degrees}deg)`
          }}></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "read-only"
    }}>{`Read only`}</h2>
    <br />
    <p>{`O modo readonly remove a interatividade do usuário e adiciona uma formatação específica de acordo com o design system da Pismo.`}</p>
    <br />
    <Playground __position={51} __code={'() => {\n  const [input, setInput] = useState()\n  const inputRef = useRef()\n  useEffect(() => {\n    if (inputRef.current && !input) {\n      setInput(new Input(inputRef.current))\n    }\n    return () => {\n      if (input) input.destroy()\n    }\n  }, [inputRef.current, input])\n  return (\n    <div className=\"tw-flex tw-gap-4 tw-flex-wrap\">\n      <div\n        ref={inputRef}\n        data-label=\"Type\"\n        data-value=\"Checking Account\"\n        data-readonly\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useRef,
      useEffect,
      useState,
      Playground,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [input, setInput] = useState();
        const inputRef = useRef();
        useEffect(() => {
          if (inputRef.current && !input) {
            setInput(new Input(inputRef.current));
          }

          return () => {
            if (input) input.destroy();
          };
        }, [inputRef.current, input]);
        return <div className="tw-flex tw-gap-4 tw-flex-wrap">
        <div ref={inputRef} data-label="Type" data-value="Checking Account" data-readonly></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "propriedades"
    }}>{`Propriedades`}</h2>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// entradas válidas através do elemento data
// ex: <div data-name='name' data-label='nome' data-value='Fulano' data-clearicon={true} />
export interface IInputDataset {
  name?: string;
  label?: string;
  tooltip?: string;
  type?: string;
  helpertext?: string;
  starticon?: string;
  endicon?: string;
  clearicon?: string;
  error?: boolean;
  disabled?: boolean;
  max?: number;
  maxlength?: number;
  min?: number;
  step?: number;
  value?: string;
  readonly?: string;
}

export interface IRef extends HTMLElement {
  dataset: Partial<Record<keyof IInputDataset, string>>;
}

//construtor - deve receber um elemento HTML com as propriedades de IInputDataset
export interface IInputConstructor {
  new (ref: IRef): IInput;
}

export interface IInput {
  readonly destroy: () => void; // método para desmontar o componente
  readonly focus: () => void; // método para dar foco no input
  readonly blur: () => void; // método para tirar o foco do input

  onChange?: (ev: InputEvent) => void; // método executado no evento onChange
  onFocus?: (ev: InputEvent) => void; // método executado quando o componente tiver foco
  onBlur?: (ev: InputEvent) => void; // método executado quando o componente perder o foco
  onStartIconClick?: (ev: MouseEvent) => void; // método executado quando clicar no starticon
  onEndIconClick?: (ev: MouseEvent) => void; // método executado quando clicar no endicon
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      